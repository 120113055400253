@tailwind base;
@tailwind components;
@tailwind utilities;



body{
  width: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  background-color: #f1f1f1;
  background-attachment: fixed;
  
}
.flexCol{
  display: flex;
  flex-direction: column;
  justify-content: center;
}
h1{
  @apply text-[70px] sm:text-[90px] md:text-[120px] lg:text-[160px] leading-[0.75em] font-league;
}
h3{
  @apply text-[14px] sm:text-[18px] md:text-[22px] lg:text-[30px] font-medium;
}
.x-container {
  @apply md:w-[60%]  max-w-[2000px] mx-auto;
}

#burger-menu {
  cursor: pointer;
  height: 27px;
  width: 27px;
  overflow: visible;
  position: relative;
  z-index: 3;
}

#burger-menu span,
#burger-menu span:before,
#burger-menu span:after {
  background: #fff;
  display: block;
  height: 4px;
  opacity: 1;
  position: absolute;
  transition: 0.3s ease-in-out;
}

#burger-menu span:before,
#burger-menu span:after {
  content: "";
}

#burger-menu span {
  right: 0px;
  top: 13px;
  width: 27px;
}

#burger-menu span:before {
  left: 0px;
  top: -10px;
  width: 16px;
}

#burger-menu span:after {
  left: 0px;
  top: 10px;
  width: 20px;
}

#burger-menu.close span {
  transform: rotate(-45deg);
  top: 13px;
  width: 27px;
}

#burger-menu.close span:before {
  top: 0px;
  transform: rotate(90deg);
  width: 27px;
}

#burger-menu.close span:after {
  top: 0px;
  left: 0;
  transform: rotate(90deg);
  opacity: 0;
  width: 0;
}

#menu {
  z-index: 2;
  min-width: 100%;
  min-height: 100%;
  position: fixed;
  top: 0;
  height: 0;
  visibility: hidden;
  opacity: 0;
  padding-top: 40px;
  transition: all 0.3s ease-in-out;
}

#menu.overlay {
  visibility: visible;
  opacity: 1;
  padding-top: 100px;
  background: rgba(33, 33, 33, 0.93);
}

#menu li {
  list-style: none;
}

#menu a {
  color: #fff;
  display: block;
  font-size: 32px;
  margin-top: 1em;
  text-decoration: none;
  text-align: center;
}






/* The actual timeline (the vertical ruler) */
.timeline::after {
  content: '';
  position: absolute;
  width: 5px;
  background-color: #909090;
  top: -2em;
  bottom: -3em;
  left: 30%;
}

/* Container around content */
.container {
  text-align: left;
  padding: 0 0 0 20px ;
  position: relative;
    left: 30%;
    width: fit-content;

}

/* The circles on the timeline */
.container::after {
  content: '';
  position: absolute;
  width: 25px;
  height: 25px;
  right: -17px;
  background-color: #e6e6e6;
  border: 5px solid #909090;
  top: 10px;
  border-radius: 50%;
  z-index: 1;
  
}
@media (max-width: 640px) {
  .container::after {
    top: 0px;

  }

}
/* Fix the circle for containers on the right side */
.container::after {
  left: -10px;
}

/* The actual content */
.content {
  padding: 0 0 0 30px;
  position: relative;
}
@media (max-width: 640px) {
  .content{
    padding: 0 0 0 10px;
  }
}
/* Move the date to the left side */
.timelinedate{
  position: absolute;
  text-align: right;
  @apply text-[16px] sm:text-[20px] md:text-[24px] lg:text-[30px]
}

.active{
  color: black;
  background: #f1f1f1;
  border-radius: 10px 10px 0 0;

}
.gridBox{
  transition: 0.2s ease-in;
}
.gridBox:hover{
  box-shadow: 1px 1px 10px 3px #b5b5b5;
}
.socialMediaIcon{
  transition: 0.2s ease-in;
  border-radius: 50%;
  box-shadow: 0;
  background: white;

}
.socialMediaIcon:hover{
  box-shadow: 1px 1px 10px 3px #9d9d9d;

}




.gridBox {
  position: relative;
}

.imageOverlay{
  transition: 0.2s ease-in;

}
.gridBox:hover .imageOverlay {
  opacity: 1;
}

.imageOverlay {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(28, 28, 28, 0.733);
  color: #fff;
  text-align: left;
  padding-left: 7%;
}





.loading {
  opacity: 0;
  transition: opacity 0.3s;
}

.loading.loaded {
  opacity: 1;
} 



textarea:focus, input:focus{
  outline: 1px solid #777777;

}

.pics{
  box-shadow: 1px 1px 5px 3px #b5b5b5;

}

/* HTML: <div class="loader"></div> */
.loader {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.loader:before {
  content: '';
  position: absolute;
  inset: 0;
  border-radius: 50%;
  --c:#0000, #FF4A4A 1deg 120deg,#0000 121deg;
  background:
    conic-gradient(from 0deg,  var(--c)) top right,
    conic-gradient(from 120deg,var(--c)) bottom,
    conic-gradient(from 240deg,var(--c)) top left;
  background-size: 40px 40px;
  background-repeat: no-repeat;
  animation: l25 2s infinite cubic-bezier(0.3,1,0,1);
}
@keyframes l25 {
   33%  {inset:-8px;transform: rotate(0deg)}
   66%  {inset:-8px;transform: rotate(180deg)}
   100% {inset:0   ;transform: rotate(180deg)}
}
@media (max-width: 400px) { 
  .loader{
    width: 30px;
    height:30px;
  }


}
.submitBtn{
  transition: 0.2s ease-out;
}
.submitBtn:hover{
  background-color: #e6e6e6;
  color: black;
  
}


.testimonial{
  box-shadow: 1px 1px 10px 3px #9d9d9d;

}